@import '~purecss/build/pure.css';
@import '~purecss/build/grids-responsive.css';
@import 'css/vars.scss';
@import 'css/shared/purecss-extensions.scss';
@import 'css/shared/base-elements.scss';
@import 'css/pages/privacypolicy.scss';
@import 'css/shared/form.scss';

html,
body {
    background: $black-1;
}

#login-container {
    width: 300px;
    margin: 0 auto;
    position: relative;
    padding: 10px;
    margin-bottom: 20px;
}

#login-form label {
    display: block;
    font-weight: 500;
}

#button-container {
    text-align: center;
}

#logo {
    width: 300px;
    margin: 30px auto;
}

.button-generic {
    color: $white-1 !important;
}

.button-generic {
    padding: 10px 30px 10px 30px;
    font-size: 22px;
    font-weight: 400;
    border-radius: 0px;
    background: $purple-4;
    box-shadow: 0px 0px 10px $purple-3;
}

.button-generic:hover,
.button-generic:active,
.button-generic:focus {
    background: $purple-5;
    box-shadow: 0px 0px 10px $purple-4;
}
