@import 'css/vars.scss';

/**
 * Here to provide reasonable defaults
 */

html,
button,
input,
select,
textarea,
.pure-g [class*='pure-u'] {
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
        'Helvetica Neue', Arial, sans-serif;
    font-weight: 300;
}

body {
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
        'Helvetica Neue', Arial, sans-serif;
    background: $black-1;
    font-weight: 300;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $black-5;
    font-weight: 500;
    margin-top: 5px;
    margin-bottom: 5px;
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
        'Helvetica Neue', Arial, sans-serif;
}

a {
    color: $blue-5;
}

a:visited {
    color: $purple-5;
}

.button-generic {
    padding: 10px 30px 10px 30px;
    font-size: 22px;
    font-weight: 400;
    border-radius: 0px;
    background: $purple-4;
    box-shadow: 0px 0px 10px $purple-3;
    color: $white-1 !important;
    white-space: normal;
}
.button-generic:hover,
.button-generic:active,
.button-generic:focus {
    background: $purple-5;
    box-shadow: 0px 0px 10px $purple-4;
}

.button-login {
    padding: 10px 30px;
    font-size: 22px;
    font-weight: 400;
    border-radius: 0px;
    background: $redorange-3;
    // box-shadow: 0 0 5px $orange-3;
    color: $white-1 !important;
    margin-right: 10px;
}

.button-login:hover,
.button-login:active,
.button-login:focus {
    background: lighten($redorange-3, 3%);
    box-shadow: 0 0 5px $redorange-3;
}

.button-cancel {
    padding: 10px 30px 10px 30px;
    font-size: 22px;
    font-weight: 400;
    border-radius: 0px;
    background: $white-2;
    box-shadow: 0px 0px 10px $white-3;
    color: $black-5 !important;
    white-space: normal;
}
.button-cancel:hover,
.button-cancel:active,
.button-cancel:focus {
    background: $white-5;
    box-shadow: 0px 0px 10px $white-4;
}

.button-control {
    padding: 0.5em 1em;
    border-radius: 0px;
    background: $purple-3;
    color: $white-1;
}

.button-control:hover,
.button-control:active,
.button-control:focus {
    background: $purple-4;
}

.button-control-red {
    padding: 0.5em 1em;
    border-radius: 0px;
    background: $red-3;
    color: $white-1;
}

.button-control-red:hover,
.button-control-red:active,
.button-control-red:focus {
    background: $red-4;
}

.button-success,
.button-error,
.button-secondary {
    color: $white-1;
    border-radius: 4px;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

.button-success {
    background: $green-4;
}

.button-error {
    background: $red-3; /* this is a maroon */
}

.no-margin {
    margin: 0px !important;
}

.no-padding {
    padding: 0px !important;
}

.white {
    color: $white-1;
}

.invisible {
    visiblity: none;
    opacity: 0;
    display: none;
}

.invisible-2 {
    visibility: none;
    opacity: 0;
    z-index: -1;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.small {
    font-size: 0.8em;
}

.light {
    color: $black-3;
}

.clearfix {
    clear: both;
}

@media (max-width: $lg-break) {
    .button-login {
        margin-right: 0;
        margin-bottom: 20px;
    }
}

.alert.alert-warn {
    border-radius: 0;
    background: #f7f9e4;
    border: 2px solid #ccc168;
}

.mt-10 {
    margin-top: 10px;
}

.ReactModalPortal {
    position: relative;
    z-index: 21472987;
}

.modal-close {
    background: none;
    border: none;
    font-size: 40px;
}
