input[type='text'],
input[type='password'],
input[type='email'],
input[type='url'],
input[type='date'],
input[type='month'],
input[type='time'],
input[type='datetime'],
input[type='datetime-local'],
input[type='week'],
input[type='number'],
input[type='search'],
input[type='tel'],
input[type='color'],
input[type='file'],
select,
textarea {
    display: block;
    // margin: 0.25em 0;
    padding: 0.5em 0.6em;
    border: 1px solid #ccc;
    -webkit-box-shadow: inset 0 1px 3px #ddd;
    box-shadow: inset 0 1px 3px #ddd;
    border-radius: 4px;
    vertical-align: middle;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
}

select {
    height: 37px;
}

select {
    display: block;
    // font-size: 16px;
    // font-family: sans-serif;
    // font-weight: 700;
    color: #444;
    line-height: 1.3;
    padding: 0.6em 1.4em 0.5em 0.8em;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    border: 1px solid #aaa;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
    border-radius: 0.5em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: url('/public/img/downarrow.svg'),
        linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 0.65em auto, 100%;
}
select::-ms-expand {
    display: none;
}
select:hover {
    border-color: #888;
}
select:focus {
    border-color: #aaa;
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222;
    outline: none;
}
select option {
    font-weight: normal;
}

/* Disabled styles */
select:disabled,
select[aria-disabled='true'] {
    color: graytext;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
        linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
}
select:disabled:hover,
select[aria-disabled='true'] {
    border-color: #aaa;
}

.date {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.date input,
.date select {
    width: auto;
    flex-grow: 1;
}

input.error,
select.error,
textarea.error {
    border: 2px solid $red-4;
    box-shadow: 0px 0px 5px $white-1;
}

div.error {
    background: $red-4;
    padding: 0.5em;
    margin-top: 0.5em;
    border-radius: 5px;
    font-weight: 500;
    color: $white-1;
}

label {
    display: block;
    margin: 0.25em 0;
    font-weight: 500;
    text-align: left;
}

.button-controls {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}

button {
    @extend .button-control;
    font-family: inherit;
    font-size: 100%;
    padding: 0.5em 1em;
    box-sizing: border-box;
    border: none;
    text-decoration: none;
    display: inline-block;
    zoom: 1;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.scroller {
    max-height: 300px;
    // padding-left: 10px;
    // padding-right: 10px;
    box-sizing: border-box;
    overflow-y: scroll;
    overflow-x: auto;
    background: $white-1;
    color: $black-5;
    margin: 0 auto;
    text-align: initial;
    border-radius: 7px;
    border: 1px solid $black-2;
}

.must-message {
    transition: 0.3s all ease-in-out;
    margin-bottom: 10px;
}

.checkbox-align {
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.checkbox-align input[type='checkbox'] {
    flex-grow: 0;
}

.checkbox-align label {
    flex-grow: 1;
}

/*progress-bar*/
#progress-bar {
    margin-bottom: 30px;
    overflow: hidden;
    /*CSS counters to number the steps*/
    counter-reset: step;
    padding: 0px;
}

#progress-bar li {
    list-style-type: none;
    color: $black-3;
    text-transform: uppercase;
    font-size: 11px;
    width: 25%;
    float: left;
    position: relative;
    text-align: center;
}

#progress-bar li.touched:hover {
    cursor: pointer;
}

#progress-bar li.active:hover {
    cursor: default;
}

#progress-bar li:before {
    content: counter(step);
    counter-increment: step;
    width: 20px;
    line-height: 20px;
    display: block;
    font-size: 10px;
    color: $white-1;
    background: $black-2;
    border-radius: 3px;
    margin: 0 auto 5px auto;
    z-index: 1;
    position: relative;
}
/*progress-bar connectors*/
#progress-bar li:after {
    content: '';
    width: 100%;
    height: 2px;
    background: $black-2;
    position: absolute;
    left: -50%;
    top: 9px;
    z-index: 0; /*put it behind the numbers*/
}
#progress-bar li:first-child:after {
    /*connector not needed before the first step*/
    content: none;
}
/*marking active/completed steps green*/
/*The number of the step and the connector before it = green*/
#progress-bar li.touched:before,
#progress-bar li.touched:after {
    background: $red-5;
    color: $white-1;
}

#progress-bar li.complete:before,
#progress-bar li.complete:after {
    background: $green-4;
    color: $white-1;
}

#progress-bar li.active:before,
#progress-bar li.active:after {
    background: $purple-2;
    color: $white-1;
}

#progress-bar li.error:before,
#progress-bar li.error:after {
    background: $red-5;
    color: $white-1;
}

progress {
    width: 100%;
    height: 20px;
    margin: 0;
    background-color: $purple-4;
    border: 1px solid $black-5;
    border-radius: 10px;
    overflow: hidden;
    -webkit-appearance: none;
}

progress[value],
progress[value]::-webkit-progress-bar {
    background-color: $white-3;
}

progress[value]::-moz-progress-bar {
    background-color: $purple-4;
}

progress::-webkit-progress-value {
    background-color: $purple-4;
}

.folder {
    border: 1px solid $purple-2;
    background: $purple-1;
    color: $white-2 !important;
    margin-top: 30px;
    margin-bottom: 10px;
    padding: 10px;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
}

.folder h2 {
    text-align: left;
    line-height: 1;
    font-size: 1.2em;
    text-transform: uppercase;
    width: 80%;
    color: $white-2;
}

.folder.open {
    background: $purple-3;
    border: 1px solid $purple-4;
    // margin-top: 0;
    // margin-bottom: 0;
}

.folder.complete {
    background: $green-4;
    border: 1px solid $green-5;
    margin-top: 0;
    margin-bottom: 0;
}

.folder.open.complete {
    margin-bottom: 10px;
}

.folder.complete:before {
    font-weight: 900;
    font-family: 'icomoon';
    content: '\f058';
    display: flex;
    align-items: center;
    position: absolute;
    right: 40px;
    font-size: 2em;
    top: 0;
    height: 100%;
}

.folder:after {
    font-family: 'icomoon';
    content: '\f077';
    font-weight: 900;
    display: flex;
    align-items: center;
    position: absolute;
    right: 10px;
    top: 0;
    height: 100%;
    transition: 0.3s all ease-in-out;
}

.folder.open:after {
    transform: rotate(180deg);
}

.acknowledgement-body {
    height: 0px;
    margin: 0px;
    padding: 0px;
    overflow: hidden;
    // opacity: 0;
    // visibilty: none;
    transition: 0.3s all ease-in-out;
}

.acknowledgement-body.open {
    margin: initial;
    padding: initial;
    overflow: visible;
    opacity: 1;
    visibility: visible;
    height: 100%;
}

.sign-display {
    color: #646262;
}

.bold {
    font-weight: bold;
}

.bigger {
    font-size: 1.2em;
}

.loan-amount {
    text-align: center;
    color: $green-6;
    font-size: 1.8em;
    font-weight: bold;
    margin-bottom: 30px;
    // margin-top: -19px;
    // padding-top: 25px;
    // background: $white-1;
    // width: 150px;
    // padding-bottom: 15px;
    // margin-left: auto;
    // margin-right: auto;
}

label.bigger {
    margin-top: 1em;
}

.relative {
    position: relative;
}

.slider,
.progress-range {
    -webkit-appearance: none;
    width: 100%;
    max-width: 100%;
    height: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 5px;
    background: $white-4;
    outline: none;
    -webkit-transition: opacity 0.15s ease-in-out;
    transition: opacity 0.15s ease-in-out;
}

.progress-range {
    overflow: hidden;
    background: none;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 0;
}

.slider.readonly {
    opacity: 0.5;
    cursor: not-allowed;
}

.slider.readonly::-webkit-slider-thumb,
.slider.readonly::-moz-range-thumb {
    cursor: not-allowed;
}

.slider:hover {
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 30px; /* Set a specific slider handle width */
    height: 30px; /* Slider handle height */
    border-radius: 15px;
    border: 8px solid $green-6;
    box-shadow: 0 0 2px $black-4;
    background: $white-1; /* Green background */
    cursor: pointer; /* Cursor on hover */
    box-sizing: border-box;
    position: relative;
    z-index: 3000;
}

.progress-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    background: none;
    width: 15px;
    height: 15px;
    box-shadow: -100vw 0 0 calc(100vw - 12px) $purple-5;
    z-index: 2;
}

.slider::-moz-range-thumb {
    width: 30px; /* Set a specific slider handle width */
    height: 30px; /* Slider handle height */
    border-radius: 15px;
    border: 8px solid $green-6;
    box-shadow: 0 0 2px $black-4;
    background: $white-1; /* Green background */
    cursor: pointer; /* Cursor on hover */
    box-sizing: border-box;
}

.progress-range::-moz-range-thumb {
    display: none;
    height: 0px;
    width: 0px;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    background: none;
    border: none;
    outline: none;
    box-shadow: none;
}

.slider::-moz-range-progress {
    background-color: $purple-5;
    border-radius: 5px;
    height: 15px;
}

.slider::-ms-fill-lower {
    background-color: $purple-5;
}

.choice-button {
    background: $black-4;
    color: $white-1;
    padding: 0.25em 1.7em;
    box-shadow: 1px 1px 4px #777;
}

.choice-button h2 {
    color: $white-1;
}

.choice-button:hover {
    background: $black-2;
}

.choice-button.active {
    background: $purple-3;
    border: 5px solid $white-1;
    border-radius: 100em;
    font-size: 5em;
    padding: 0.1em 1em;
}

.choice-button.top {
    font-weight: 500;
    font-size: 1.8em;
    border-top-left-radius: 1.5em;
    border-top-right-radius: 1.5em;
    border-top: 3px solid $white-1;
    border-left: 3px solid $white-1;
    border-right: 3px solid $white-1;
}

.choice-button.bottom {
    font-weight: 500;
    font-size: 1.8em;
    border-bottom-left-radius: 1.5em;
    border-bottom-right-radius: 1.5em;
    border-bottom: 3px solid $white-1;
    border-left: 3px solid $white-1;
    border-right: 3px solid $white-1;
}

.choice-details {
    margin-top: 20px;
    width: 100%;
}

.choices {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 30px;
}

.choice {
    padding: 1.5em 1em 1em 1em;
    border: 3px solid $black-5;
}

.apr {
    margin-bottom: 20px;
    margin-top: 5px;
}

.choice-info {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    border-bottom: 1px solid $black-1;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.choice-info span:nth-child(1) {
    font-size: 0.8em;
    padding-right: 20px;
}

// SM
@media screen and (min-width: $sm-break) {
}

// MD
@media screen and (min-width: $md-break) {
}

// LG
@media screen and (min-width: $lg-break) {
}

// XL
@media screen and (min-width: $xl-break) {
}

/* set thumb width */
// input[type=range], input[type=range] + datalist { --thumb-width: 15px; }

// input[type=range] + datalist {
//     display: flex;
//     justify-content: space-between;
//     padding: calc(var(--thumb-width)) calc(var(--thumb-width));
// }

// input [type=range] + datalist option {
//     position: relative;
//     display: flex;
//     justify-content: center;
//     width: 1px;
//     background: gray;
// }

/* style range */
// input[type=range] {
//   -webkit-appearance: none; /* hide track and thumb */
//   width: 100%;
//   max-width: 100%;
//   margin-left: 0;
// }

/* style datalist */
// input[type=range] + datalist {
//     display: block;
//     margin-top: -4px;
// }
// input[type=range] + datalist option {
//     display: inline-block;
//     white-space: no-break;
//     width: calc((100% - var(--thumb-width)) / (var(--list-length) - 1));
//     text-align: center;
//     content: "|";
// }
// input[type=range] + datalist option:first-child {
//     width: calc((100% - var(--thumb-width)) / ((var(--list-length) - 1) * 2) + var(--thumb-width) / 2);
//     text-align: left;
// }
// input[type=range] + datalist option:last-child {
//     width: calc((100% - var(--thumb-width)) / ((var(--list-length) - 1) * 2) + var(--thumb-width) / 2);
//     text-align: right;
// }

/* style Firefox range and thumb */
// input[type=range]::-moz-range-track {
//     background: #eee;
//     cursor: pointer;

//     height: 2px;
//     border: 1px solid #888;
//     border-radius: 1px;
// }
// input[type=range]::-moz-range-thumb {
//     background: #eee;

//     box-sizing: border-box;
//     width: var(--thumb-width);
//     height: 20px;

//     cursor: pointer;

//     border: 1px solid #888;
//     border-radius: 3px;
// }

// /* style Chrome range and thumb */
// input[type=range]::-webkit-slider-runnable-track {
//     background: #eee;
//     cursor: pointer;

//     height: 2px;
//     border: 1px solid #888;
//     border-radius: 1px;
// }
// input[type=range]::-webkit-slider-thumb {
//     background: #eee;

//     box-sizing: border-box;
//     width: var(--thumb-width);
//     height: 20px;

//     cursor: pointer;

//     border: 1px solid #888;
// }

@media (max-width: $sm-break) {
    .choice-button.active {
        font-size: 4em;
    }
    .choice-button.top,
    .choice-button.bottom {
        font-size: 1.5em;
    }
}
