@import 'css/vars.scss';

.privacy-header {
    text-align: right;
}

.table-header {
    color: $white-1;
    background: #767171 !important;
    font-weight: bold;
}

.table-header.dark-header {
    background: $black-2 !important;
}

th.table-header {
    vertical-align: top;
}

.pure-no-greed {
    padding: .1em;
}

.odd-positioning {
    vertical-align: top;
}

.pure-table.pure-table-bordered {
    border-color: $black-5;
    width: 100%;
}

.pure-table-bordered td {
    border-color: $black-5;
}

.pure-table td {
    vertical-align: top;
}

.pure-table p,
.pure-table ul {
    margin-top: 0px;
}

.no-style-list {
    list-style: none;
}
.overflow-table {
    width: 100%;
    position: relative;
    overflow-x: auto;
}

#privacy-policy-render {
    margin: 10px auto;
}

@media (max-width: 410px) {
    #privacy-policy-render {
        max-width: 80vw;
    }
}
